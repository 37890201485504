.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

i.ion-help.floatr {
  position: absolute;
  right: 30px;
  font-size: 11px;
  color: #7b7b7b;
  background: #f4f6f9;
  padding: 3px 8px;
  margin-top: 5px;
  border-radius: 50%;
}

i.ion-help.floatr-top {
  position: absolute;
  right: 30px;
  font-size: 11px;
  color: #7b7b7b;
  background: #f4f6f9;
  padding: 3px 8px;
  margin-top: -24px;
  border-radius: 50%;
}
.mb-1a{
  margin-bottom: 1.4px!important;
}

.card-margin {
  margin-top: 95px;
}


.modalTopper{
  margin-top: 95px;
}

 .navbar {
   background: #060D1C;
   font-family: 'Alegreya Sans', sans-serif;
 }

 .btn-dark-blue {
   background: #060D1C !important;
   border-color: #060D1C !important;
   color: white !important;
 }

 a.navbar-brand img {
   width: 120px;
   height: auto;
   margin-top: -5px;
 }

 .navbar-inverse .navbar-nav>.active>a,
 .navbar-inverse .navbar-nav>.active>a:focus,
 .navbar-inverse .navbar-nav>.active>a:hover {
   background: none !important;
   border-bottom: 3px solid #42acec;
 }

 .navbar-inverse .navbar-nav>li>a:focus,
 .navbar-inverse .navbar-nav>li>a:hover {
   border-bottom: 3px solid #42acec;
 }

 .navbar-inverse .navbar-nav>li>a {
   background: none !important;
   color: white;
   padding-bottom: 0px !important;
   padding-left: 0px !important;
   padding-right: 0px !important;
   margin-left: 7px !important;
   margin-right: 7px !important;
 }

 .steps-col {
   padding: 0px 40px;
 }

 .steps-col p {
   margin-top: 20px;
 }

 .card {
   -webkit-box-shadow: 4px 3px 20px -5px rgba(163, 163, 163, 1);
   -moz-box-shadow: 4px 3px 20px -5px rgba(163, 163, 163, 1);
   box-shadow: 4px 3px 20px -5px rgba(163, 163, 163, 1);
   padding: 20px;
   background: white;
 }

 .thin-label {
   font-weight: normal;
   padding: 0px;
 }

 .form-control {
   outline: none;

   box-shadow: none;

   -webkit-appearance: none;
   -moz-appearance: none;
   -ms-appearance: none;
   -o-appearance: none;
   appearance: none;
 }

 .capitalize {text-transform: capitalize !important;}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sweetLoader{
  display: block;
  margin-top: 10%;
  margin-left: 50%;
  margin-right: 40%;
  border-color: red;
}
.styles_closeButton__20ID4{
      position: absolute;
      top: 45px !important;
      right: 14px;
      border: none;
      padding: 0;
      background-color: transparent;
      display: flex;
}


.failed-payment-container {
  height: 100vh;
  background-color: #f7fcfc;
}

.failed-payment-container .top-text{
   font-size: 18px;
   margin-bottom: 28px ;
}
